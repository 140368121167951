import { template } from "@ember/template-compiler";
import { hash, fn } from '@ember/helper';
import LegacyCard from '@smile-io/ember-shopify-polaris/components/legacy-card';
import IndexTable from '@smile-io/ember-shopify-polaris/components/index-table';
import Text from '@smile-io/ember-shopify-polaris/components/text';
import Badge from '@smile-io/ember-shopify-polaris/components/badge';
const headings = (showRequiredPlan1)=>{
    return [
        {
            title: 'Name'
        },
        ...(showRequiredPlan1 ? [
            {
                title: 'Plan name'
            }
        ] : []),
        {
            title: 'Category'
        }
    ];
};
export default template(`
  <LegacyCard @title={{@title}}>
    <IndexTable
      @selectable={{false}}
      @loading={{@loading}}
      @resourceName={{hash singular="report" plural="reports"}}
      @headings={{headings @showRequiredPlan}}
      @itemCount={{@reports.length}}
      as |indexTable|
    >
      {{#each @reports as |report index|}}
        <indexTable.Row
          @id={{report.id}}
          @position={{index}}
          @onClick={{fn @onViewReport report}}
        >
          <indexTable.Cell>
            <Text @variant="bodyMd" @text={{report.name}} />
          </indexTable.Cell>

          {{#if @showRequiredPlan}}
            <indexTable.Cell>
              {{report.requiredPlan}}
            </indexTable.Cell>
          {{/if}}

          <indexTable.Cell>
            <Badge @text={{report.category}} />
          </indexTable.Cell>
        </indexTable.Row>
      {{/each}}
    </IndexTable>
  </LegacyCard>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
