import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { storageFor } from 'ember-local-storage';

export default class SimplifiedAnalyticsRoute extends Route {
  @service featureRollouts;
  @service router;
  @service sesh;

  @storageFor('sweet-tooth-session') localStorage;

  beforeModel() {
    if (
      this.localStorage.get('isAdmin') &&
      this.featureRollouts.get('improveUsabilityOnAnalyticsPage')
    ) {
      return;
    }

    this.router.replaceWith('analytics');
  }
}
