import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { query } from '../../utils/ember-data/builders';

export default class SimplifiedAnalyticsDashboardRoute extends Route {
  @service store;

  model() {
    return {
      analyticsRequest: this.store.request(query('daily-account-summary', {})),
      dataFreshnessRequest: this.store
        .request(query('data-freshness-stat-set', { table_name: 'global' }))
        .then((result) => (result.content?.length ? result.content[0] : null)),
    };
  }
}
