import { template } from "@ember/template-compiler";
import { hash, array } from '@ember/helper';
import { BlockStack } from '@smile-io/ember-shopify-polaris/components/block-stack';
import { Box } from '@smile-io/ember-shopify-polaris/components/box';
import { Button } from '@smile-io/ember-shopify-polaris/components/button';
import { Card } from '@smile-io/ember-shopify-polaris/components/card';
import { InlineStack } from '@smile-io/ember-shopify-polaris/components/inline-stack';
import { SearchResourceIcon } from '@smile-io/polaris-icons/components/search-resource-icon';
import { Text } from '@smile-io/ember-shopify-polaris/components/text';
import { Tooltip } from '@smile-io/ember-shopify-polaris/components/tooltip';
import { SkeletonDisplayText } from '@smile-io/ember-shopify-polaris/components/skeleton-display-text';
import chartData from 'smile-admin/helpers/chart-data';
import EmberChart from 'smile-admin/components/ember-chart';
import { smileDefaultChartOpts } from 'smile-admin/helpers/analytics-charts';
export default template(`
  <Card @padding="400">
    <BlockStack @gap="200" @align="space-between">
      <Box @minHeight="24px">
        <InlineStack @align="space-between" @blockAlign="center">
          {{#let (component Text variant="bodyLg" text=@title) as |Text|}}
            {{#if @tooltipTitle}}
              <Tooltip
                @active={{this.tooltipActive}}
                @content={{@tooltipTitle}}
                @hasUnderline={{true}}
                @preferredPosition="below"
              >
                <Text />
              </Tooltip>
            {{else}}
              <Text />
            {{/if}}
          {{/let}}

          {{#if @onViewMetric}}
            <Button
              @variant="tertiary"
              @onClick={{@onViewMetric}}
              @icon={{SearchResourceIcon}}
            />
          {{/if}}
        </InlineStack>
      </Box>

      <InlineStack @align="space-between" @blockAlign="center">
        {{#if @isLoading}}
          <Box @width="120px" @paddingBlockStart="200">
            <SkeletonDisplayText @size="medium" />
          </Box>
        {{else}}
          <Text @variant="headingLg">
            {{@value}}
          </Text>

          {{#if @data}}
            <Box @width="120px" @minHeight="40px">
              <EmberChart
                @type="line"
                @options={{smileDefaultChartOpts}}
                @height="40px"
                @data={{chartData
                  chartStyle="singleTealLine"
                  series=(array (hash data=@data))
                }}
              />
            </Box>
          {{/if}}
        {{/if}}
      </InlineStack>
    </BlockStack>
  </Card>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
