/* import __COLOCATED_TEMPLATE__ from './new-start-guide.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { storageFor } from 'ember-local-storage';
import moment from 'moment-timezone';

export default class NewStartGuide extends Component {
  @storageFor('sweet-tooth-session')
  localStorage;

  constructor() {
    super(...arguments);
    this.expandedStepIndex = this.firstIncompleteTaskIndex;
  }

  get firstIncompleteTaskIndex() {
    return this.args.steps.findIndex((step) => !step.isComplete);
  }

  get completedSteps() {
    return this.args.steps.filter((step) => step.isComplete);
  }

  get progress() {
    return (this.completedSteps.length / this.args.steps.length) * 100;
  }

  get showGuide() {
    const dismissedTimestamp = this.localStorage.get('dismissedGuideTime');

    if (!dismissedTimestamp) {
      return true;
    }

    const timeDifference = moment().diff(moment(dismissedTimestamp), 'hours');

    return timeDifference > 24;
  }
  @action
  dismissGuide() {
    const currentTime = moment().toISOString();
    this.localStorage.set('dismissedGuideTime', currentTime);
  }
}
