import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { hash } from '@ember/helper';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { capitalize, dasherize } from '@ember/string';
import { REPORTS } from 'smile-admin/utils/analytics';
const availableReports = (features1)=>REPORTS.filter((report1)=>!report1.requiredFeatureFlag || features1.get(report1.requiredFeatureFlag));
const unavailableReports = (features1)=>REPORTS.filter((report1)=>report1.requiredFeatureFlag && !features1.get(report1.requiredFeatureFlag)).map((report1)=>{
        return {
            ...report1,
            requiredPlan: capitalize(report1.requiredFeatureFlag.replace('reports', ''))
        };
    });
export default class ReportsTable extends Component {
    @service
    features;
    @service
    router;
    @action
    handleViewReport(report1) {
        if (report1.requiredFeatureFlag && !this.features.get(report1.requiredFeatureFlag)) {
            this.router.transitionTo('settings.billing');
            return;
        }
        this.router.transitionTo('simplified-analytics.reports.show', dasherize(report1.key));
    }
    static{
        template(`
    {{yield
      (hash
        available=(availableReports this.features)
        unavailable=(unavailableReports this.features)
        onViewReport=this.handleViewReport
      )
    }}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
