/* import __COLOCATED_TEMPLATE__ from './view-offer-banner.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { storageFor } from 'ember-local-storage';
import { alias } from '@ember/object/computed';

export default class ViewOfferBanner extends Component {
  @service billing;

  @service featureRollouts;

  @storageFor('sweet-tooth-session')
  localStorage;

  @alias('localStorage.lastDismissedViewOfferBanner')
  lastDismissedViewOfferBanner;

  /**
   * Wrapper component for the banner.
   *
   * @type {Component}
   * @default null
   * @public
   */
  wrapperComponent = null;

  /**
   * Billing provider with actions/tasks ( {{billing-provider}} )
   *
   * @type {Object}
   * @default null
   * @public
   * @required
   */
  provider = null;

  /**
   * Flag to toggle showing billing subscription view offer modal
   *
   * @type {Boolean}
   * @default false
   * @public
   */
  get presentOffer() {
    return this.args.presentOffer || false;
  }

  get hasSubscriptionOffer() {
    return this.billing.hasSubscriptionOffer;
  }

  get subscriptionOffer() {
    return this.billing.subscriptionOffer;
  }

  get shouldShowBanner() {
    return (
      this.hasSubscriptionOffer &&
      this.lastDismissedViewOfferBanner !== this.subscriptionOffer.id
    );
  }

  @action
  dismissBanner() {
    this.lastDismissedViewOfferBanner = this.subscriptionOffer.id;
  }
}
