import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { storageFor } from 'ember-local-storage';
import createApp, { LifecycleHook } from '@shopify/app-bridge';
import {
  History,
  Redirect,
  AppLink,
  NavigationMenu,
} from '@shopify/app-bridge/actions';
import {
  isShopifyEmbedded,
  getSessionToken,
} from '@shopify/app-bridge/utilities';
import { datadogLogs } from '@datadog/browser-logs';

const oauthParamKeys = ['host', 'hmac', 'shop', 'timestamp'];

export default class ShopifyService extends Service {
  @service config;
  @service errorHandler;
  @service router;
  @service sesh;
  @service featureRollouts;

  @storageFor('shopify') shopifyStorage;
  @storageFor('sweet-tooth-session') localStorage;

  @tracked appBridge;
  @tracked lastTransition;

  get redirect() {
    if (!this._redirect) {
      this._redirect = Redirect.create(this.appBridge);
    }

    return this._redirect;
  }

  get shouldSkipEmbedding() {
    // If we don't have a `host` query param, 💯 not a Shopify store that can be embedded
    if (!this.oauthParams.host) {
      return true;
    }

    return false;
  }

  get oauthParams() {
    return this.shopifyStorage.getProperties(oauthParamKeys);
  }

  /**
   *  True, when the app is embedded in Shopify admin.
   *
   * NOTE we're also checking for the existence of all the Shopify oauth params
   * because Shopify's `isShopifyEmbedded` function is not reliable, in that it
   * only checks if the app is loaded in an iframe. For Smile this is also true
   * on Bigcommerce platform, so we need to check for the oauth params to be
   * sure.
   */
  get isEmbedded() {
    return hasAllShopifyOauthParams() && isShopifyEmbedded();
  }

  get history() {
    if (!this.isEmbedded || !this.appBridge) {
      return null;
    }

    this._history = this._history || History.create(this.appBridge);
    return this._history;
  }

  /**
   * Part of experiment to improve LCP on the home page by skipping API calls and show somethings static instead.
   *  https://smileio.atlassian.net/browse/AS-409
   */
  get showStaticCardsOnHomePage() {
    return (
      this.lastTransition?.to?.name === 'home' &&
      this.isEmbedded &&
      this.sesh.isOnboardingComplete &&
      !this.localStorage.get('lockedPendingPayment') &&
      !this.localStorage.get('shouldSeeLockoutScreen')
    );
  }

  init() {
    super.init(...arguments);
    this.setup();
  }

  setup() {
    this._persistOauthParams();

    if (this.shouldSkipEmbedding) {
      return;
    }

    this.appBridge = createApp({
      apiKey: this.config.get('shopify.clientId'),
      host: this.oauthParams.host,
    });

    this.logWebVitalsToDatadog();

    this.router.on('routeWillChange', (transition) => {
      // We keep track of the last transition to use in `showStaticCardsOnHomePage` getter
      this.lastTransition = transition;
    });
  }

  logWebVitalsToDatadog() {
    if (!this.appBridge?.hooks) {
      return;
    }

    const logWebVitalsToDatadog = (next) => {
      return (action) => {
        const { group, payload, clientInterface, type } = action;

        if (group === 'WebVitals') {
          datadogLogs.logger.info(payload.metricName, {
            ...payload,
            clientInterface,
            type,
            location: window.location.pathname,
          });
        }

        return next(action);
      };
    };

    this.appBridge.hooks.set(
      LifecycleHook.DispatchAction,
      logWebVitalsToDatadog
    );
  }

  async attemptEmbedding(transition) {
    if (this.shouldSkipEmbedding) {
      return;
    }

    // Trigger oauth if needed
    if (shouldTriggerOauth()) {
      // Abort current transition
      // We don't want to let the app to continue loading, instead we abort the transition to allow
      // Shopify oauth to happen and show loading indicator in that time.
      transition.abort();
      await this.triggerOauth();

      return;
    }

    if (!this.isEmbedded) {
      // If the app is not yet embedded, we stop transitioning so that we continue showing the
      // loading indicator until Shopify embeds the app in the store admin.
      transition.abort();
    } else {
      this._handleShopifyNavigation();
    }
  }

  async triggerOauth() {
    if (this.shouldSkipEmbedding) {
      return;
    }

    const oauthUrlParams = new URLSearchParams(this.oauthParams).toString();
    const oauthUrl = `${this.config.get(
      'publicApiHost'
    )}/v1/auth/shopify?${oauthUrlParams}`;

    if (this.isEmbedded) {
      this.redirect.dispatch(Redirect.Action.REMOTE, oauthUrl);
    } else {
      window.location.assign(oauthUrl);
    }
  }

  async fetchSessionToken() {
    if (!this.isEmbedded || !this.appBridge) {
      throw new Error(
        'Shopify session tokens are available only when embedded in Shopify!'
      );
    }

    return getSessionToken(this.appBridge);
  }

  pushHistory(path) {
    if (!this.history) {
      return;
    }

    this.history.dispatch(History.Action.PUSH, path);
  }

  replaceHistory(path) {
    if (!this.history) {
      return;
    }

    this.history.dispatch(History.Action.REPLACE, path);
  }

  createNavigationMenu() {
    let { appBridge } = this;
    let navItems = [];

    const programLink = AppLink.create(appBridge, {
      label: 'Program',
      destination: '/reward-programs',
    });
    const customerLink = AppLink.create(appBridge, {
      label: 'Customers',
      destination: '/customers',
    });
    const performanceLink = AppLink.create(appBridge, {
      label: 'Analytics',
      destination: '/analytics',
    });
    const onSiteContentLink = AppLink.create(appBridge, {
      label: 'On-site content',
      destination: '/on-site',
    });
    const integrationsLink = AppLink.create(appBridge, {
      label: 'Integrations',
      destination: '/integrations',
    });
    const settingsLink = AppLink.create(appBridge, {
      label: 'Settings',
      destination: '/settings',
    });

    if (
      !this.sesh.account?.lockedPendingPayment ||
      !this.sesh.account?.shouldSeeLockoutScreen
    ) {
      navItems = [
        programLink,
        customerLink,
        performanceLink,
        ...(this.featureRollouts.get('onSiteContent')
          ? [onSiteContentLink]
          : []),
        integrationsLink,
        settingsLink,
      ];
    }

    NavigationMenu.create(appBridge, {
      items: navItems,
    });
  }

  /**
   * By default, Shopify App Bridge applies URL changes from outside our app, such as from a
   * navigation item being clicked, by changing the iframe URL. Since our app uses client-side
   * routing, we need to override this behaviour to avoid unnecessary full-page reloads. When an app
   * has a subscription to this action, App Bridge doesn't change the iframe URL. Instead, it passes
   * the path to this callback function, where we can pass the path to our client-side router.
   * Source https://shopify.dev/apps/tools/app-bridge/actions/navigation#using-client-side-routing
   * */
  _handleShopifyNavigation() {
    this.appBridge.subscribe(Redirect.Action.APP, (redirectData) => {
      this.router.transitionTo(redirectData.path);
    });
  }

  /**
   * Persists Shopify OAuth params if they exist for later use.
   * Shopify will append these query params when our app is accessed from a merchant's store admin.
   */
  _persistOauthParams() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    oauthParamKeys.forEach((param) => {
      if (urlSearchParams.get(param)) {
        this.shopifyStorage.set(`${param}`, urlSearchParams.get(param));
      }
    });
  }
}

const hasAllShopifyOauthParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return oauthParamKeys.every((paramKey) => urlSearchParams.has(paramKey));
};

/**
 * We check if this is an initial app install based on a few conditions:
 *    - we did not just finish doing oauth and got redirected back from `core` (`embedded` query
 *      param not present)
 *    - it has all the oauth query params passed by Shopify.
 */
const shouldTriggerOauth = () => {
  if (window.top !== window.self) {
    return false;
  }

  return hasAllShopifyOauthParams();
};
