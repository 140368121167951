/* import __COLOCATED_TEMPLATE__ from './referral-share-link.hbs'; */
import Component from '@ember/component';
import EmberObject, { computed } from '@ember/object';
import { gt } from '@ember/object/computed';
import { isNone } from '@ember/utils';
import { inject as service } from '@ember/service';
import { storageFor } from 'ember-local-storage';
import { task } from 'ember-concurrency';
import moment from 'moment-timezone';
import { types as trackableTaskTypes } from 'smile-admin/models/trackable-task';
import { types as notificationTypes } from 'smile-admin/models/customer-notification';

const referralsDashboardRoute = 'reward-programs.referrals.dashboard';

export default Component.extend({
  tagName: '',

  router: service(),
  sesh: service(),
  store: service(),

  referralLinksTrackableTask: null,

  localStorage: storageFor('sweet-tooth-session'),

  hasRequiredNoOfMembers: gt('sesh.account.members_count', 1),

  shouldRender: computed(
    'localStorage.hasDismissedSendReferralLinksCard',
    'hasRequiredNoOfMembers',
    function () {
      return (
        !this.localStorage.get('hasDismissedSendReferralLinksCard') &&
        this.hasRequiredNoOfMembers
      );
    }
  ),

  startReferralLinksTrackableTask: task(
    function* startReferralLinksTrackableTask() {
      let referralsProgram = this.sesh.referralsRewardProgram;
      let currentRoute = this.router.currentRouteName;
      let referralLinksTrackableTask =
        yield referralsProgram.sendReferralSharingLinks();

      if (currentRoute === referralsDashboardRoute) {
        this.set('referralLinksTrackableTask', referralLinksTrackableTask);
      } else {
        this.router.transitionTo(referralsDashboardRoute);
      }
    }
  ).drop(),

  fetchReferralLinksTrackableTask: task(
    function* fetchReferralLinksTrackableTask() {
      let { store, sesh } = this;
      let type = trackableTaskTypes.referralSharingLinkEmail;

      // Check store first - task may have already been loaded on
      // the home route if user is seeing this card on the referrals
      // dashboard route.
      let referralLinksTasks = store.peekAll('trackable-task');
      let referralLinksTrackableTask = referralLinksTasks.find(
        (task) => task.type === type
      );

      if (referralLinksTrackableTask) {
        return referralLinksTrackableTask;
      }

      referralLinksTasks = yield store.query('trackable-task', {
        account_id: sesh.account.id,
        type,
      });

      return referralLinksTasks[0];
    }
  ).drop(),

  checkReferralLinksTrackableTask: task(
    function* checkReferralLinksTrackableTask() {
      let { fetchReferralLinkNotification, fetchReferralLinksTrackableTask } =
        this;
      let referralLinksTrackableTask =
        yield fetchReferralLinksTrackableTask.perform();

      if (isNone(referralLinksTrackableTask)) {
        return fetchReferralLinkNotification.perform();
      }

      let now = moment();
      let taskFinished = referralLinksTrackableTask.finishedAt;
      let taskCompletedInHoursAgo = taskFinished
        ? now.diff(taskFinished, 'hours')
        : 0;

      if (taskCompletedInHoursAgo >= 24) {
        this.send('dismissCard');
      } else {
        this.set('referralLinksTrackableTask', referralLinksTrackableTask);
      }
    }
  ).drop(),

  fetchReferralLinkNotification: task(
    function* fetchReferralLinkNotification() {
      let notifications = yield this.store.query('customer-notification', {
        type: notificationTypes.referralSharingLink,
        include: 'preview_data,email_html_template',
      });
      let notification = notifications[0];
      let previewData = notification.previewData || EmberObject.create({});
      let templateVariables = Object.keys(previewData);

      this.setProperties({
        referralLinkNotification: notification,
        notificationTemplateVariables: templateVariables,
        notificationPreviewData: previewData,
      });
    }
  ).drop(),

  init() {
    this._super(...arguments);

    if (this.shouldRender) {
      this.checkReferralLinksTrackableTask.perform();
    }
  },

  actions: {
    dismissCard() {
      let dismiss = this.onDismiss;

      if (dismiss) {
        dismiss('hasDismissedSendReferralLinksCard');
      }
    },
  },
});
