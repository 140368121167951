import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { fn, hash, concat } from '@ember/helper';
import { gt, and, not } from 'ember-truth-helpers';
import { getPromiseState } from '@warp-drive/ember';
import { dasherize } from '@ember/string';
import momentFrom from 'ember-moment/helpers/moment-from';
import momentDiff from 'ember-moment/helpers/moment-diff';
import now from 'ember-moment/helpers/now';
import BlockStack from '@smile-io/ember-shopify-polaris/components/block-stack';
import InlineGrid from '@smile-io/ember-shopify-polaris/components/inline-grid';
import Layout from '@smile-io/ember-shopify-polaris/components/layout';
import Banner from '@smile-io/ember-shopify-polaris/components/banner';
import Page from '@smile-io/ember-shopify-polaris/components/page';
import Text from '@smile-io/ember-shopify-polaris/components/text';
import { SearchResourceIcon } from '@smile-io/polaris-icons/components/search-resource-icon';
import MetricCard from 'smile-admin/components/route-page/analytics/dashboard/metric-card';
import { formatNumber } from 'smile-admin/helpers/format-number';
import { formatCurrency } from 'smile-admin/helpers/format-currency';
import ReportsTable from 'smile-admin/components/route-page/analytics/reports-table';
import ReportsProvider from 'smile-admin/components/route-page/analytics/reports-provider';
import { REPORT_IDS } from 'smile-admin/utils/analytics';
const sumBy = (arr1, key1)=>arr1.reduce((acc1, obj1)=>acc1 + obj1[key1], 0);
const seriesBy = (arr1, key1)=>arr1.map((obj1)=>({
            x: obj1.date,
            y: obj1[key1]
        }));
// TODO add error handling
export default class RoutePageAnalyticsDashboard extends Component {
    @service
    router;
    @service
    sesh;
    @service
    session;
    get accountDataSet() {
        return this.sesh.account.dataSet;
    }
    transitionToReport = (reportId1)=>{
        this.router.transitionTo('simplified-analytics.reports.show', dasherize(reportId1));
    };
    transitionToReports = ()=>{
        this.router.transitionTo('simplified-analytics.reports');
    };
    dismissBanner = ()=>{
        this.session.set('dismissedDataFreshnessBanner', true);
    };
    static{
        template(`
    {{#let
      (getPromiseState @analyticsRequest)
      (getPromiseState @dataFreshnessRequest)
      as |analyticsRequestState dataFreshnessRequestState|
    }}
      <Page
        @title="Analytics"
        @subtitle={{if
          (and
            dataFreshnessRequestState.isSuccess
            dataFreshnessRequestState.result.lastUpdatedAt
          )
          (concat
            "Data on this page updates daily. Last updated "
            (momentFrom dataFreshnessRequestState.result.lastUpdatedAt)
          )
          "Data on this page updates daily."
        }}
        @primaryAction={{hash
          content="View all reports"
          icon=SearchResourceIcon
          onAction=this.transitionToReports
        }}
      >
        <Layout as |layout|>
          {{#if
            (and
              (not this.session.dismissedDataFreshnessBanner)
              (gt
                (momentDiff
                  dataFreshnessRequestState.result.lastUpdatedAt
                  (now)
                  precision="hours"
                )
                24
              )
            )
          }}
            <layout.Section>
              <Banner
                @title="Some data on this page is over 24 hours old"
                @tone="warning"
                @onDismiss={{this.dismissBanner}}
              >
                We’re currently experiencing a delay in updating your data.
                We’re working to resolve the issue, and it should be fixed soon.
                Thanks for your patience!
              </Banner>
            </layout.Section>
          {{/if}}

          <layout.Section>
            <BlockStack @gap="200">
              <Text @variant="headingMd">Since you launched</Text>

              <InlineGrid @gap="400" @columns={{hash xs=1 md=2 lg=3}}>
                <MetricCard
                  @title="Loyalty revenue"
                  @tooltipTitle="Total sales with a Smile discount code"
                  @isLoading={{analyticsRequestState.isPending}}
                  @value={{formatCurrency
                    this.accountDataSet.totalOrderValueWithSmileDiscountCode
                    this.sesh
                  }}
                />
                <MetricCard
                  @title="Program members"
                  @tooltipTitle="Customers who have created a store account"
                  @isLoading={{analyticsRequestState.isPending}}
                  @value={{formatNumber this.accountDataSet.totalMembers}}
                  @onViewMetric={{fn
                    this.transitionToReport
                    REPORT_IDS.totalMembersOverTime
                  }}
                />
                <MetricCard
                  @title="Orders with a Smile discount"
                  @tooltipTitle="Total orders with a Smile discount code"
                  @isLoading={{analyticsRequestState.isPending}}
                  @value={{formatNumber
                    this.accountDataSet.totalOrdersWithSmileDiscountCode
                  }}
                />
              </InlineGrid>
            </BlockStack>
          </layout.Section>

          <layout.Section>
            <BlockStack @gap="200">
              <Text @variant="headingMd">Last 30 days</Text>

              <InlineGrid @gap="400" @columns={{hash xs=1 md=2 lg=3}}>
                <MetricCard
                  @title="Members added"
                  @tooltipTitle="Customers who joined your loyalty progarm by creating a store account"
                  @isLoading={{analyticsRequestState.isPending}}
                  @value={{formatNumber
                    (sumBy
                      analyticsRequestState.result.content "totalNewMembers"
                    )
                  }}
                  @data={{seriesBy
                    analyticsRequestState.result.content
                    "totalNewMembers"
                  }}
                  @onViewMetric={{fn
                    this.transitionToReport
                    REPORT_IDS.totalMembersOverTime
                  }}
                />
                {{! TODO add @onViewMetric to this card - pending disucssion with Bill }}
                <MetricCard
                  @title="Earners"
                  @tooltipTitle="Customers who have earned points"
                  @isLoading={{analyticsRequestState.isPending}}
                  @value={{formatNumber this.accountDataSet.totalEarners28d}}
                  @data={{seriesBy
                    analyticsRequestState.result.content
                    "totalEarners"
                  }}
                />
                {{! TODO add @onViewMetric to this card - pending disucssion with Bill }}
                <MetricCard
                  @title="Redeemers"
                  @tooltipTitle="Customers who have redeemed points for a reward. Typically a discount code."
                  @isLoading={{analyticsRequestState.isPending}}
                  @value={{formatNumber this.accountDataSet.totalRedeemers28d}}
                  @data={{seriesBy
                    analyticsRequestState.result.content
                    "totalRedeemers"
                  }}
                />
                <MetricCard
                  @title="Loyalty revenue"
                  @tooltipTitle="Total sales with a Smile discount code"
                  @isLoading={{analyticsRequestState.isPending}}
                  @value={{formatCurrency
                    (sumBy
                      analyticsRequestState.result.content
                      "totalOrderValueWithSmileDiscountCode"
                    )
                    this.sesh
                  }}
                  @data={{seriesBy
                    analyticsRequestState.result.content
                    "totalOrderValueWithSmileDiscountCode"
                  }}
                  @onViewMetric={{fn
                    this.transitionToReport
                    REPORT_IDS.salesInfluencedBySmileOverTime
                  }}
                />
                <MetricCard
                  @title="Points transactions"
                  @tooltipTitle="Total number of times points have been earned, redeemed, expired, or adjusted."
                  @isLoading={{analyticsRequestState.isPending}}
                  @value={{formatNumber
                    (sumBy
                      analyticsRequestState.result.content
                      "totalPointsTransactions"
                    )
                  }}
                  @data={{seriesBy
                    analyticsRequestState.result.content
                    "totalPointsTransactions"
                  }}
                  @onViewMetric={{fn
                    this.transitionToReport
                    REPORT_IDS.listOfAllPointsTransactions
                  }}
                />

                {{#if this.sesh.hasReferralsInstalled}}
                  <MetricCard
                    @title="Referral purchases"
                    @tooltipTitle="Total orders placed by referred customers"
                    @isLoading={{analyticsRequestState.isPending}}
                    @value={{formatNumber
                      (sumBy
                        analyticsRequestState.result.content
                        "totalReferralsCompleted"
                      )
                    }}
                    @data={{seriesBy
                      analyticsRequestState.result.content
                      "totalReferralsCompleted"
                    }}
                    @onViewMetric={{fn
                      this.transitionToReport
                      REPORT_IDS.salesFromReferredCustomersOverTime
                    }}
                  />
                {{/if}}
              </InlineGrid>
            </BlockStack>
          </layout.Section>

          <layout.Section>
            <BlockStack @gap="200">
              <Text @variant="headingMd">Loyalty reports</Text>

              <ReportsProvider as |reports|>
                <ReportsTable
                  @reports={{reports.available}}
                  @onViewReport={{reports.onViewReport}}
                />
              </ReportsProvider>
            </BlockStack>
          </layout.Section>
        </Layout>
      </Page>
    {{/let}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
